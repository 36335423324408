@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: #1a1a2e 15% 12%; /* Dark navy blue, akin to a very dark night sky */
    --foreground: 0 0% 100%; /* Pure white for clear, crisp text */

    --card: 210 28% 18%; /* Dark slate blue for cards to provide a subtle contrast with the background */
    --card-foreground: 0 0% 100%; /* White text for good readability on card elements */

    --popover: 210 28% 20%; /* Slightly lighter than cards for popovers to make them stand out */
    --popover-foreground: 0 0% 100%; /* White text to maintain clarity and focus */

    --primary: 207 90% 54%; /* Bright blue for primary buttons and interactive elements */
    --primary-foreground: 0 0% 18%; /* Dark navy text for a unique, stylish contrast on primary elements */

    --secondary: 207 70% 35%; /* Muted blue for secondary elements, less intense than the primary */
    --secondary-foreground: 0 0% 100%; /* White text to ensure legibility against the softer blue */

    --muted: #1a1a2e 25% 20%; /* Dark gray-blue for less important elements that require subtlety */
    --muted-foreground: 0 0% 88%; /* Light gray text for a softer appearance */

    --accent: 345 80% 65%; /* Soft red for accents to add a touch of energy without overwhelming */
    --accent-foreground: 0 0% 100%; /* White text on accents to pop against the vibrant red */

    --destructive: 0 80% 60%; /* Vivid red for alerts or destructive actions like delete or cancel */
    --destructive-foreground: 0 0% 100%; /* White text to stand out clearly and maintain safety */

    --border: 210 28% 25%; /* Border color similar to card but slightly lighter to define edges */
    --input: 210 28% 18%; /* Same as cards for input fields to maintain visual cohesion */
    --ring: 207 90% 54%; /* Same as primary color for focus rings to highlight interactive focus */
  }

  .dark-2 {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
